import './App.css';

import {useState} from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Chip, Divider,  IconButton , SvgIcon , Stack, Tooltip, Tab, Typography} from '@mui/material';
import { TabList , TabPanel, TabContext } from '@mui/lab';
import GitHubButton from 'react-github-btn'

import Job from './components/Job/Job';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function App() {

  const [projectValue, setProjectValue] = useState(0);
  const [experienceValue, setExperienceValue] = useState(0);


  const handleProjectChange = (_, newValue) => {
    setProjectValue(newValue);
  };

  const handleExperienceChange = (_, newValue) => {
    setExperienceValue(newValue);
  };

  const jobs = [
    {
      "employer": "Cisco",
      "positions": [
        {
          "title": "Software Engineer II",
          "start": new Date(2023, 10, 1), 
          "end": new Date(), 
          "skills": ["Apache Kafka", "Redis", "Rust", "Java", "Typescript", "MongoDB"],
          "description": [
            "Helped develop a Lifecycle Platform for Cisco servers, that tracked the patch status, discovery information, checklists, and installed software.",
            "Developed a ETL tool in Rust, using the Tokio async framework, that reads data from kafka and enchanced it, and then stored it in MySQL",
            "Developed a Java backend for a dashboard platform",
            "Transformed the frontend to use Typescript, from vanilla javascript"
          ]
        },
        {
          "title": "Software Engineer I",
          "start": new Date(2022, 6, 18), 
          "end": new Date(2023, 10, 1), 
          "skills": ["SpringBoot", "React", "Rust", "Jenkins", "K8", "Docker", "RedHat OpenShift"],
          "description": [
            "Built a React & Java SpringBoot application that controls citrix server load balancers and global server load balancers.",
            "Developed AppDynamics, Citrix, and ServiceNow (ESP) integrations for the application.",
            "Dockerized and lead K8 deployment.",
            "Technical Point of Contact for pentest and AppOmni integration."
          ]
        }
      ]
    },
    {
      "employer": "Virginia Tech",
      "positions": [
        {
          "title": "Undergraduate Teaching Assistant",
           "start": new Date(2022, 0, 18),
           "end": new Date(2022, 4, 5),
           "skills": ["C", "Linux", "Python"],
           "description": [
            "Helped students debug programs: Shells, Threadpools, Memory Allocators, HTTP 1.1 Servers written in C.",
            "Lead help session for project",
            "Worked on Discord bot that was used in class"
           ]
        }
      ]
    },
    {
      "employer": "Peraton",
      "positions": [
        {
          "title": "Spring Semester Programming Co-op",
           "start": new Date(2021, 0, 4),
           "end": new Date(2021, 4, 13),
           "skills": ["SpringBoot", "Tomcat", "NiFi", "AWS Lambda", "SQS/SNS", "jQuery", "Python", "spaCy"],
           "description": [
            "Worked front end and backend development",
            "Worked to contanerize project using docker",
            "Created spaCy pipeline component"
           ]
        }
      ]
    }

  ]

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <Stack spacing={1} sx={{paddingLeft: '10px'}}>
        <Typography variant='h4' sx={{textAlign: 'center'}}>Abhishek Sathiabalan</Typography>
        <Typography variant='h5'>Work Experience</Typography>
        <Stack 
          sx={{paddingTop: '5px', marginTop: '5px'}}
          direction="row"
          spacing={2}
          id="experience"
        >
          <Box>
            <TabContext value={experienceValue}>
              <Stack direction='row'>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList
                  orientation="vertical"
                  value={experienceValue}
                  onChange={handleExperienceChange}
                  aria-label="wrapped label tabs example"
                  centered
                >
                  <Tab value={0} label="Cisco" />
                  <Tab value={1} label="Virginia Tech" />
                  <Tab value={2} label="Peraton" />
                </TabList>
              </Box>

              {jobs.map( (x, index)  => <>
                <TabPanel value={index}>
                  <Job data={x} />
                </TabPanel>
                </>
              )}
              </Stack>
            </TabContext>
          </Box>
        </Stack>

        <Typography variant='h5'>Projects</Typography>
        <Stack 
          sx={{paddingTop: '5px', marginTop: '5px'}}
          direction="row"
          spacing={2}
          id="projects"
        >
          <Box>
            <TabContext value={projectValue}>
              <Stack direction={'row'} >
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    orientation="vertical"
                    value={projectValue}
                    onChange={handleProjectChange}
                    aria-label="wrapped label tabs example"
                    centered
                  >
                    <Tab value={0} label="HTTP Server" />
                    <Tab value={1} label="Threadpool" />
                    <Tab value={2} label="Malloc" />
                    <Tab value={3} label="AbMath"/>
                    <Tab value={4} label="SQLite GUI Project"/>
                    <Tab value={5} label="Finance Application"/>
                  </TabList>
                </Box>
                <TabPanel value={0}>
                  <Stack direction='row' spacing={1}>
                    <Chip label="C"/>
                    <Chip label="Rust"/>
                  </Stack>
                  <Typography>
                    <ul>
                      <li>A HTTP1.1 server using threads</li>
                      <li>Supports video, jwt authentication, and private files.</li>
                    </ul>
                  </Typography>
                </TabPanel>
                <TabPanel value={1}>
                  <Stack direction='row' spacing={1}>
                    <Chip label="C"/>
                  </Stack>
                  <Typography>
                    <ul>
                      <li>A threadpool with work stealing</li>
                      <li><Tooltip title="A fully-strict model requires that tasks join tasks they spawn — in other words, every call to
                      submit a task has a matching call to future get() within the same function invocation.
                      In this sense, all tasks spawned by a task can be considered subtasks that need to complete 
                      before the task completes.">Strict model</Tooltip></li>
                    </ul>
                  </Typography>
                </TabPanel>
                <TabPanel value={2}>
                  <Stack direction='row' spacing={1}>
                    <Chip label="C"/>
                  </Stack>
                  <Typography>
                    <ul>
                      <li>A custom memory allocator in C</li>
                      <li>Implementes malloc, free, and realloc</li>
                      <li>Segregated Free List implementation</li>
                    </ul>
                  </Typography>
                </TabPanel>
                <TabPanel value={3}>
                <Stack direction='row' spacing={1}>
                    <Chip label="C#"/>
                  </Stack>
                  <Typography>
                    <ul>
                      <li>Source to Source translator</li>
                      <li>Calculate symbolic derivatives and integral approximations</li>
                      <li>Compiler techniques and rule-based optimizations</li>
                    </ul>
                  </Typography>
                </TabPanel>
                <TabPanel value={4}>
                  <Stack direction='row' spacing={1}>
                    <Chip label="C#"/>
                  </Stack>
                  <Typography>
                    <ul>
                      <li>Developed a GUI application in C# for SQLite databases.</li>
                      <li>Ability to export, import, and query database tables.</li>
                    </ul>
                  </Typography>
                </TabPanel>
                <TabPanel value={5}>
                  <Stack direction='row' spacing={1}>
                    <Chip label="PHP"/>
                  </Stack>
                  <Typography>
                    <ul>
                      <li>Track spending and balances</li>
                      <li>Written in PHP, SQLite, HTML, JS, and CSS.</li>
                    </ul>
                  </Typography>
                </TabPanel>
              </Stack>
            </TabContext>
          </Box>
        </Stack>

        <Typography variant='h5'>Skills</Typography>
        <Divider />
        <Stack
          sx={{paddingTop: '5px'}}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1} 
          alignItems={{ xs: 'left', sm: 'center' }}
          id="skills"
        >
          <Chip label="Springboot"/>
          <Chip label="React"/>
          <Chip label="Docker"/>
          <Chip label="Kubernetes"/>
          <Chip label="Red Hat OpenShift"/>
          <Chip label="Containerization"/>
          <Chip label="Kafka"/>
          <Chip label="NiFi"/>
          <Chip label="Elasticsearch"/>
          <Chip label="AWS Lambda"/>
          <Chip label="EC2"/>
          <Chip label="S3"/>
          <Chip label="SQS/SNS"/>
          <Chip label="Linux (Ubuntu, Alpine, CentOs7)"/>
        </Stack>

        <Typography variant='h5'>Languages</Typography>
        <Divider />
        <Stack
          sx={{paddingTop: '5px'}}
          direction={{ xs: 'column', sm: 'row' }}
          spacing={1}
          alignItems={{ xs: 'left', sm: 'center' }}
          id="languages"
        >
          <Chip label="C"/>
          <Chip label="C#"/>
          <Chip label="Java"/>
          <Chip label="Rust"/>
          <Chip label="Python"/>
        </Stack>

        <Stack 
          justifyContent="center"
          alignItems={{ xs: 'left', sm: 'center' }}
          sx={{paddingTop: '5px'}}
          direction="row"
          spacing={2}
          id="social-media"
        >
          <Box sx={{paddingTop: '8px'}}>
            <GitHubButton href="https://github.com/65001" data-color-scheme="no-preference: dark; light: light; dark: dark;" data-size="large" aria-label="Follow @65001 on GitHub">Follow @65001</GitHubButton>
          </Box>
          <IconButton href="https://www.linkedin.com/in/abhishek-sathiabalan/">
            <SvgIcon>
              <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" aria-label="LinkedIn" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path>
                <rect x="2" y="9" width="4" height="12"></rect>
                <circle cx="4" cy="4" r="2"></circle>
              </svg>
            </SvgIcon>
        </IconButton>
        </Stack>
      </Stack>
    </ThemeProvider>
  );
}

export default App;