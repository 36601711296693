import {useState} from 'react';
import { Box, Chip, Divider,  IconButton , SvgIcon , Stack, Tooltip, Tab, Typography, Container} from '@mui/material';

export default function Job({data}) {
    var options = { year: 'numeric', month: 'long', day: 'numeric' };
    

    function pluralize(count, singular, plural) {
        return count === 1 ? singular : plural ?? `${singular}s`;
    }

    function formatDuration(millis) {
        let date = new Date(millis);
        let parts = [];
    
        if (date.getUTCFullYear() > 1970) {
            let years = date.getUTCFullYear() - 1970;
            parts.push(years, pluralize(years, 'year'));
        }
        if (date.getUTCMonth() > 0) { // months start at zero
            let months = date.getUTCMonth();
            parts.push(months, pluralize(months, 'month'));
        }
        
        return parts.join(' ');
    }

    return (
        <Box sx={{paddingTop: '0px'}}>
            {data.positions.map( (x) => <>
                <Stack direction={{ xs: 'column', sm: 'row', paddingBottom: '5x'}} spacing={2}>
                    <Typography variant='h5'>{x.title}</Typography>
                    <Box>
                        <Tooltip title={formatDuration(x.end - x.start)}>
                            <Chip label={x.start.toLocaleDateString("en-US", options) + " - " + x.end.toLocaleDateString("en-US", options)}/>
                        </Tooltip>
                    </Box>
                </Stack>
                <Divider sx={{paddingTop: '5px', paddingBottom: '5px'}}/>
                <Box sx={{paddingTop: '5px'}}>
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={1} sx={{paddingBottom: '5px'}}>
                        {x.skills.map( (skill) => <><Chip label={skill}/></> )}
                    </Stack>
                </Box>
                <ul>
                    {x.description.map( (entry) => <>
                        <li>{entry}</li>
                    </>)}
                </ul>
                </>
            )}
        </Box>
    )
}